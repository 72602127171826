.popup {
  display: none; }
  .popup__bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 5000; }
  .popup__window {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5001;
    background: #dddddd;
    padding: 30px;
    width: 90%;
    max-width: 600px;
    box-sizing: border-box;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.5); }
  .popup__close {
    width: 35px;
    height: 35px;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    line-height: 35px;
    opacity: .5;
    background: #9a1908;
    transition: all .5s ease; }
    .popup__close:hover {
      opacity: 1;
      cursor: pointer; }
  .popup__cross {
    font-family: 'Arial', sans-serif;
    transform: rotate(45deg);
    text-align: center;
    width: 35px;
    height: 35px;
    line-height: 35px;
    color: #ffffff;
    font-size: 40px;
    transition: all .5s ease; }
    .popup__cross:hover {
      transform: rotate(-135deg); }
  .popup__title {
    font-family: 'Russo One', sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    color: #212121;
    text-align: center;
    line-height: 28px;
    margin-bottom: 20px; }
    @media screen and (max-width: 800px) {
      .popup__title {
        font-size: 16px;
        line-height: 20px; } }
  .popup__aferta {
    margin-top: 20px;
    font-size: 12px;
    color: #999999;
    width: 100%;
    text-align: center; }
