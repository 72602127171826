.jg_catelem_photo {
  width: calc(33.3333333% - 0) !important;
  float: left;
  padding: 10px !important;
  box-sizing: border-box;
  margin-right: 0; }
  @media screen and (max-width: 800px) {
    .jg_catelem_photo {
      width: calc(50% - 0) !important; } }
  .jg_catelem_photo img {
    border: 10px solid #ffffff !important;
    display: block !important;
    box-sizing: border-box; }
