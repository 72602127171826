.header {
  padding: 20px 0;
  width: 100%;
  z-index: 100;
  background: #ffffff; }
  .header__item--phone {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 600px) {
      .header__item--phone {
        text-align: center; } }
  @media screen and (max-width: 600px) {
    .header__item {
      flex-direction: column;
      width: 100%;
      text-align: center; } }
