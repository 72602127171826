.tab-content {
  background: #ffffff;
  padding: 10px 20px !important; }
  @media screen and (max-width: 1000px) {
    .tab-content {
      padding: 10px !important; } }
  .tab-content h2 {
    font-family: 'Russo One', sans-serif;
    text-align: center;
    color: #6b1105;
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 15px;
    margin-top: 5px; }
  .tab-content .moduletable {
    user-select: none; }
    .tab-content .moduletable .custom table {
      width: 100%;
      margin: 5px 0;
      transition: all .5s ease; }
      .tab-content .moduletable .custom table tr td {
        border: 1px solid #cccccc;
        border-collapse: collapse;
        font-size: 14px;
        padding: 8px 15px; }
        @media screen and (max-width: 600px) {
          .tab-content .moduletable .custom table tr td {
            width: 100% !important;
            display: block;
            box-sizing: border-box;
            padding: 5px 10px;
            padding-bottom: 0;
            border-bottom: 0;
            text-align: center;
            font-size: 12px; } }
        .tab-content .moduletable .custom table tr td:nth-child(2) {
          width: 150px;
          text-align: center; }
          @media screen and (max-width: 600px) {
            .tab-content .moduletable .custom table tr td:nth-child(2) {
              width: 100% !important;
              display: block;
              box-sizing: border-box;
              padding-top: 0;
              border-top: 0;
              text-align: center;
              padding-bottom: 5px; } }
      .tab-content .moduletable .custom table tr:nth-child(odd) {
        background: #e2e2e2; }
      .tab-content .moduletable .custom table tr:nth-child(1) td {
        text-align: center;
        font-weight: bold; }
      .tab-content .moduletable .custom table:nth-child(n+2) tr {
        display: table-row; }
        .tab-content .moduletable .custom table:nth-child(n+2) tr:nth-child(1) {
          cursor: pointer; }
          @media screen and (max-width: 600px) {
            .tab-content .moduletable .custom table:nth-child(n+2) tr:nth-child(1) td {
              padding: 5px 10px; } }
        .tab-content .moduletable .custom table:nth-child(n+2) tr:nth-child(n+2) {
          display: none; }
      .tab-content .moduletable .custom table.active {
        transition: all .5s ease; }
        .tab-content .moduletable .custom table.active tr {
          display: table-row !important; }
  .tab-content .joomcat {
    display: none; }
