.services {
  padding-bottom: 40px; }
  .services__list {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center; }
    .services__list--right {
      margin: 15px 0; }
  .services__item {
    width: calc(50% - 40px);
    margin: 0 20px;
    box-sizing: border-box;
    margin-bottom: 35px;
    overflow: hidden;
    position: relative;
    font-family: 'Russo One', sans-serif;
    background: #000000; }
    .services__item--right {
      width: 100%;
      margin-bottom: 20px; }
    @media screen and (max-width: 1200px) {
      .services__item {
        width: 100%; } }
  .services__link {
    display: block;
    max-width: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    min-height: 220px;
    transition: all .35s ease; }
    .services__link--right {
      min-height: 150px; }
    .services__link:hover {
      transform: scale(1.25); }
  .services__title {
    background: #000;
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    background: #323232;
    color: #ffffff;
    display: block;
    line-height: 30px;
    font-size: 16px;
    text-transform: uppercase;
    padding: 5px 15px;
    box-sizing: border-box;
    transition: all .5s ease;
    z-index: 50;
    text-decoration: none; }
    .services__title--right {
      font-size: 13px;
      line-height: 18px;
      padding: 8px 10px;
      font-weight: normal; }
  .services__item:hover .services__link {
    opacity: .75; }
  .services__item:hover .services__title {
    background: #9a1908;
    color: #f9be0c; }
