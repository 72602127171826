@media screen and (max-width: 600px) {
  .jg_element_txt,
  .jg_subcatelem_txt {
    padding: 0;
    margin: 0; } }

@media screen and (max-width: 600px) {
  .jg_element_txt ul,
  .jg_subcatelem_txt ul {
    padding: 0;
    margin: 0; } }

@media screen and (max-width: 600px) {
  .jg_element_txt ul li,
  .jg_subcatelem_txt ul li {
    padding: 0;
    margin: 0; } }

.jg_element_txt ul li a,
.jg_subcatelem_txt ul li a {
  width: 200px;
  margin: auto;
  display: block;
  padding: 10px 15px;
  background: #9a1908;
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  margin-top: 0;
  hyphens: auto; }
  .jg_element_txt ul li a:hover,
  .jg_subcatelem_txt ul li a:hover {
    background: #f9be0c;
    color: #212121 !important; }
  @media screen and (max-width: 600px) {
    .jg_element_txt ul li a,
    .jg_subcatelem_txt ul li a {
      width: 100%;
      font-size: 10px; } }

.jg_subcatelem_cat a {
  height: auto !important; }
