.jg_element_gal,
.jg_subcatelem_cat {
  background: #ffffff;
  margin: 0 10px;
  margin-bottom: 20px;
  width: calc(33.333333% - 20px);
  padding: 30px 10px;
  box-sizing: border-box; }
  @media screen and (max-width: 800px) {
    .jg_element_gal,
    .jg_subcatelem_cat {
      width: calc(50% - 20px);
      margin: 0 10px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 600px) {
    .jg_element_gal,
    .jg_subcatelem_cat {
      width: calc(50% - 20px);
      margin: 0 5px;
      margin-bottom: 10px;
      padding: 10px; } }
  @media screen and (max-width: 400px) {
    .jg_element_gal,
    .jg_subcatelem_cat {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
      padding: 10px; } }

.jg_element_gal:hover .jg_element_txt a,
.jg_subcatelem_cat:hover .jg_subcatelem_txt a {
  background: #f9be0c !important;
  color: #212121 !important; }
