.content {
  padding: 30px 0;
  min-height: 500px;
  box-sizing: border-box; }
  .content__center {
    width: 75%;
    padding-right: 20px;
    box-sizing: border-box; }
    @media screen and (max-width: 1200px) {
      .content__center {
        order: 2;
        width: 100%;
        padding: 0; } }
  .content__right {
    width: 25%; }
    @media screen and (max-width: 1200px) {
      .content__right {
        order: 1;
        width: 100%;
        display: none; } }
  .content h4 {
    font-weight: bold;
    margin: 15px 0 5px 0; }
  .content .zhym-map-default {
    margin-top: 25px !important; }
