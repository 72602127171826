.phone {
  margin-top: 5px;
  text-align: right; }
  @media screen and (max-width: 600px) {
    .phone {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #dddddd;
      width: 100%; } }
  @media screen and (max-width: 600px) and (max-width: 600px) {
    .phone {
      text-align: center; } }
  .phone__number {
    font-family: 'Russo One', sans-serif;
    line-height: 28px;
    font-size: 18px;
    color: #585858 !important;
    display: block;
    text-decoration: none; }
    @media screen and (max-width: 600px) {
      .phone__number {
        font-size: 16px;
        line-height: 24px; } }
  .phone__span {
    color: #9a1908 !important;
    font-size: 22px; }
    @media screen and (max-width: 600px) {
      .phone__span {
        font-size: 20px; } }
