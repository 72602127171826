.jg_pathway {
  font-size: 14px;
  margin-bottom: 15px;
  width: 100%;
  display: block;
  min-width: 100%; }
  .jg_pathway a {
    color: #9a1908;
    text-decoration: none; }
    .jg_pathway a:hover {
      text-decoration: underline; }
