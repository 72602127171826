.feedback {
  margin: 0 20px; }
  .feedback__btn {
    display: block;
    border: 2px solid #6b1105;
    line-height: 40px;
    padding: 2px 20px 0;
    color: #6b1105;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold; }
    .feedback__btn:hover {
      background: #9a1908;
      color: #f9be0c; }
