.footer {
  background: #212121;
  padding: 35px 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  line-height: 18px; }
  @media screen and (max-width: 800px) {
    .footer {
      text-align: center; } }
  .footer__item {
    max-width: 400px; }
  .footer--template {
    padding-top: 135px; }
    @media screen and (max-width: 1000px) {
      .footer--template {
        padding-top: 35px; } }
  .footer--kontakty {
    padding-top: 35px; }
