.navicon {
  display: none;
  cursor: pointer; }
  @media screen and (max-width: 1300px) {
    .navicon {
      display: block; } }
  .navicon__span {
    width: 60px;
    height: 60px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    transform: rotate(90deg);
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
    float: right; }
