.page-header {
  font-family: 'Russo One', sans-serif;
  text-align: center;
  color: #6b1105;
  text-transform: uppercase;
  font-size: 26px;
  margin-bottom: 15px;
  margin-top: 5px; }
  .page-header + .pull-left {
    max-width: 100% !important; }
