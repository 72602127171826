.mainslider {
  border-bottom: 3px solid #212121; }
  .mainslider__item {
    position: relative; }
    .mainslider__item--bg1 {
      background: url("../img/mainslider_01.jpg") 50% 50%/cover no-repeat; }
    .mainslider__item--bg2 {
      background: url("../img/mainslider_02.jpg") 50% 50%/cover no-repeat; }
    .mainslider__item--bg3 {
      background: url("../img/mainslider_03.jpg") 50% 50%/cover no-repeat; }
    .mainslider__item--bg4 {
      background: url("../img/mainslider_04.jpg") 50% 50%/cover no-repeat; }
    .mainslider__item--bg5 {
      background: url("../img/mainslider_05.jpg") 50% 50%/cover no-repeat; }
    .mainslider__item:after {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, 0.35);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20; }
  .mainslider__link {
    text-decoration: none;
    min-height: 500px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-transform: uppercase;
    font-family: 'Russo One', sans-serif;
    font-size: 55px;
    color: white;
    position: relative;
    z-index: 25;
    text-align: center; }
    @media screen and (max-width: 1200px) {
      .mainslider__link {
        font-size: 44px;
        min-height: 400px; } }
    @media screen and (max-width: 800px) {
      .mainslider__link {
        font-size: 33px;
        min-height: 300px; } }
    @media screen and (max-width: 500px) {
      .mainslider__link {
        font-size: 20px;
        min-height: 200px; } }
