.jg_photo {
  border: 0 !important;
  display: block !important;
  margin: auto !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: auto !important;
  max-width: 200px !important;
  max-height: 200px !important; }
  @media screen and (max-width: 600px) {
    .jg_photo {
      max-width: 100% !important;
      max-height: auto !important; } }
