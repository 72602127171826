.logo {
  position: relative;
  user-select: none; }
  @media screen and (max-width: 900px) {
    .logo {
      margin-bottom: 5px;
      width: 100%; } }
  .logo__link {
    text-decoration: none;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center; }
  .logo__img {
    width: 150px; }
    @media screen and (max-width: 900px) {
      .logo__img {
        width: 100px; } }
  .logo__name {
    font-family: 'Russo One', sans-serif;
    color: #212121;
    font-size: 26px;
    padding: 7px 12px 3px;
    display: block;
    text-transform: uppercase;
    font-weight: bold; }
    @media screen and (max-width: 900px) {
      .logo__name {
        font-size: 20px; } }
  .logo__slogan {
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    color: #434343; }
    @media screen and (max-width: 900px) {
      .logo__slogan {
        font-size: 10px; } }
