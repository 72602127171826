.wrapper {
  width: 95%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column; }
  .wrapper--header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
    @media screen and (max-width: 600px) {
      .wrapper--header {
        flex-direction: column;
        width: 100%; } }
  .wrapper--content {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .wrapper--consultation {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
    @media screen and (max-width: 1000px) {
      .wrapper--consultation {
        flex-direction: column; } }
  .wrapper--contacts {
    background: #ffffff;
    transform: translateY(-50%);
    padding: 15px;
    box-sizing: border-box;
    min-height: 200px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border: 1px solid #cccccc; }
    @media screen and (max-width: 1000px) {
      .wrapper--contacts {
        position: relative;
        transform: none;
        background: none;
        border: none;
        flex-direction: column;
        min-height: 0;
        margin: 20px 0; } }
  .wrapper--footer {
    flex-direction: row;
    justify-content: space-between; }
    @media screen and (max-width: 800px) {
      .wrapper--footer {
        flex-direction: column; } }
