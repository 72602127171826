.advantages {
  background: #cccccc; }
  .advantages__list {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    flex-wrap: wrap; }
  .advantages__item {
    width: 25%;
    padding: 0 20px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    align-items: center; }
    @media screen and (max-width: 1200px) {
      .advantages__item {
        width: 50%; } }
    @media screen and (max-width: 800px) {
      .advantages__item {
        width: 100%; } }
  .advantages__iconcase {
    width: 150px;
    height: 150px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 50%;
    overflow: hidden;
    background: #6b1105;
    position: relative; }
    .advantages__iconcase:before {
      content: '';
      position: absolute;
      width: 95%;
      height: 95%;
      border: 1px solid #ffffff;
      left: 2.5%;
      top: 2.5%;
      box-sizing: border-box;
      border-radius: 50%;
      overflow: hidden; }
    @media screen and (max-width: 800px) {
      .advantages__iconcase {
        width: 100px;
        height: 100px; } }
  .advantages__icon {
    width: 65px;
    height: 65px;
    display: block; }
    @media screen and (max-width: 800px) {
      .advantages__icon {
        width: 40px;
        height: 40px; } }
  .advantages__textcase {
    text-align: center;
    margin-top: 30px; }
  .advantages__title {
    text-align: center;
    font-family: 'Russo One', sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    color: #212121;
    margin-bottom: 10px; }
  .advantages__text {
    text-align: center;
    font-weight: bold;
    color: #666666;
    font-size: 14px; }
