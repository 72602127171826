.section__titlecase {
  margin-top: 45px;
  margin-bottom: 45px;
  text-transform: uppercase;
  font-family: 'Russo One', sans-serif;
  text-align: center; }

.section__title {
  font-size: 40px;
  color: #212121;
  margin-bottom: 10px;
  font-weight: bold; }
  @media screen and (max-width: 1200px) {
    .section__title {
      font-size: 25px; } }
  .section__title--small {
    font-size: 30px; }
    @media screen and (max-width: 1200px) {
      .section__title--small {
        font-size: 20px; } }

.section__subtitle {
  font-size: 20px;
  color: #999999; }
  @media screen and (max-width: 1200px) {
    .section__subtitle {
      font-size: 14px; } }
