.gallery {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .gallery .pngfile {
    display: none; }
  .gallery .jg-header {
    display: none; }
