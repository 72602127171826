.joomimg102_main,
.joomimg104_main,
.joomimg105_main,
.joomimg106_main,
.joomimg107_main,
.joomimg108_main {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
