@media screen and (max-width: 1000px) {
  .yandexmap {
    display: none; } }

.ymaps-2-1-60-ground-pane {
  filter: grayscale(100%); }

.ymaps-2-1-60-copyright__wrap {
  display: none;
  opacity: 0;
  visibility: hidden;
  z-index: -999;
  position: absolute; }
