.creative {
  background: url("../img/creative_01.jpg") 50% 50%/cover no-repeat #c99237 fixed;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media screen and (max-height: 750px) {
    .creative {
      height: auto;
      max-height: auto; } }
