.contacts {
  position: absolute;
  width: 100%; }
  @media screen and (max-width: 1000px) {
    .contacts {
      position: relative;
      transform: none; } }
  .contacts__item {
    padding: 10px;
    box-sizing: border-box;
    width: 33.333333%;
    text-align: center; }
    @media screen and (max-width: 800px) {
      .contacts__item {
        width: 100%; } }
  .contacts__title {
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-weight: bold;
    color: #666666; }
  .contacts__text {
    font-family: 'Russo One', sans-serif;
    text-align: center;
    font-size: 22px;
    color: #212121;
    line-height: 30px;
    display: block;
    text-decoration: none; }
    .contacts__text--link:hover {
      color: #9a1908;
      text-decoration: underline; }
    @media screen and (max-width: 800px) {
      .contacts__text {
        font-size: 18px;
        line-height: 24px; } }
