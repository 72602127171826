.joomimg102_imgct,
.joomimg104_imgct,
.joomimg105_imgct,
.joomimg106_imgct,
.joomimg107_imgct,
.joomimg108_imgct {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  @media screen and (max-width: 1000px) {
    .joomimg102_imgct,
    .joomimg104_imgct,
    .joomimg105_imgct,
    .joomimg106_imgct,
    .joomimg107_imgct,
    .joomimg108_imgct {
      width: 50% !important; } }
  @media screen and (max-width: 600px) {
    .joomimg102_imgct,
    .joomimg104_imgct,
    .joomimg105_imgct,
    .joomimg106_imgct,
    .joomimg107_imgct,
    .joomimg108_imgct {
      width: 100% !important; } }
