.simpleform__item {
  width: 100%;
  margin-bottom: 10px; }
  .simpleform__item label {
    display: none; }
  .simpleform__item--hidden {
    display: none; }

.simpleform__input {
  display: block;
  width: 100%;
  background: #ffffff;
  line-height: 40px;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 18px;
  font-family: 'roboto';
  border: 2px solid #dddddd; }
  .simpleform__input:focus {
    border: 2px solid #dddddd; }

.simpleform__btn {
  font-family: 'Russo One', sans-serif;
  background: #9a1908;
  color: #ffffff;
  line-height: 30px;
  font-size: 16px;
  padding: 5px 15px;
  text-transform: uppercase;
  box-sizing: border-box;
  transition: all .5s ease;
  text-decoration: none;
  display: block;
  cursor: pointer;
  width: 100%; }
  .simpleform__btn:hover {
    background: #6b1105;
    color: #f9be0c; }
  @media screen and (max-width: 1000px) {
    .simpleform__btn {
      margin-top: 8px; } }
