.item-page img,
.item-page_about img,
.item-page_contacts img,
.item-page_services img,
.item-page_works img {
  max-width: 100%;
  margin: 10px 0; }

.item-page .pull-right,
.item-page_about .pull-right,
.item-page_contacts .pull-right,
.item-page_services .pull-right,
.item-page_works .pull-right {
  margin: 10px 0;
  margin-left: 25px; }
  @media screen and (max-width: 1000px) {
    .item-page .pull-right,
    .item-page_about .pull-right,
    .item-page_contacts .pull-right,
    .item-page_services .pull-right,
    .item-page_works .pull-right {
      display: block !important;
      margin: 20px auto !important;
      float: none !important;
      clear: both !important;
      max-width: 200px !important; } }

.item-page .pull-left,
.item-page_about .pull-left,
.item-page_contacts .pull-left,
.item-page_services .pull-left,
.item-page_works .pull-left {
  margin: 10px 0;
  margin-right: 25px; }
  @media screen and (max-width: 1000px) {
    .item-page .pull-left,
    .item-page_about .pull-left,
    .item-page_contacts .pull-left,
    .item-page_services .pull-left,
    .item-page_works .pull-left {
      display: block !important;
      margin: 20px auto !important;
      float: none !important;
      clear: both !important;
      max-width: 200px !important; } }

.item-page p,
.item-page_about p,
.item-page_contacts p,
.item-page_services p,
.item-page_works p {
  text-indent: 20px;
  line-height: 25px;
  text-align: justify;
  font-size: 16px;
  margin-bottom: 5px; }

.item-page ul,
.item-page_about ul,
.item-page_contacts ul,
.item-page_services ul,
.item-page_works ul {
  margin-top: 15px;
  padding-left: 25px;
  margin-bottom: 15px; }
  .item-page ul li,
  .item-page_about ul li,
  .item-page_contacts ul li,
  .item-page_services ul li,
  .item-page_works ul li {
    margin-bottom: 10px;
    line-height: 20px;
    padding-left: 30px;
    font-size: 16px;
    text-align: justify;
    background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNTEwIDUxMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEwIDUxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJjaGVjay1jaXJjbGUtYmxhbmsiPgoJCTxwYXRoIGQ9Ik0yNTUsMEMxMTQuNzUsMCwwLDExNC43NSwwLDI1NXMxMTQuNzUsMjU1LDI1NSwyNTVzMjU1LTExNC43NSwyNTUtMjU1UzM5NS4yNSwwLDI1NSwweiIgZmlsbD0iI0Q4MDAyNyIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=) 10px 6px/6px 6px no-repeat; }

.item-page ol,
.item-page_about ol,
.item-page_contacts ol,
.item-page_services ol,
.item-page_works ol {
  margin-top: 15px;
  padding-left: 25px;
  margin-bottom: 15px;
  padding-left: 50px; }
  .item-page ol li,
  .item-page_about ol li,
  .item-page_contacts ol li,
  .item-page_services ol li,
  .item-page_works ol li {
    margin-bottom: 10px;
    line-height: 20px;
    padding-left: 5px;
    font-size: 16px;
    list-style: outside decimal;
    text-align: justify; }

.item-page_works,
.item-page_services,
.item-page_contacts,
.item-page_about {
  background: #ffffff;
  padding: 20px 30px !important;
  box-sizing: border-box;
  margin: 15px 0;
  border: 1px solid #dddddd; }
  @media screen and (max-width: 1000px) {
    .item-page_works,
    .item-page_services,
    .item-page_contacts,
    .item-page_about {
      padding: 10px; } }
