.social {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media screen and (max-width: 900px) {
    .social {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5000;
      margin-top: -20px; } }
  .social__title {
    font-weight: bold;
    line-height: 14px;
    margin-bottom: 10px;
    font-size: 14px; }
    @media screen and (max-width: 900px) {
      .social__title {
        display: none; } }
  .social__list {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    @media screen and (max-width: 900px) {
      .social__list {
        background: #ffffff;
        padding-bottom: 5px;
        padding-right: 5px; } }
  .social__link {
    display: block;
    width: 30px;
    height: 30px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 5px;
    border-radius: 5px;
    overflow: hidden;
    transition: all .35s ease;
    filter: grayscale(100%); }
    @media screen and (max-width: 900px) {
      .social__link {
        margin: 5px 0 0 5px; } }
    .social__link--vk {
      background-image: url("../img/social_icon_vk@2x.svg"); }
    .social__link--ig {
      background-image: url("../img/social_icon_ig@2x.svg"); }
    .social__link:hover {
      opacity: .75;
      filter: none; }
