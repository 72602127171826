.navigate {
  background: #212121;
  transition: all .5s ease; }
  .navigate .wrapper > ul {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    @media screen and (max-width: 1300px) {
      .navigate .wrapper > ul {
        display: none;
        flex-direction: column;
        width: 100%; } }
    .navigate .wrapper > ul > li:last-child:hover {
      background: none; }
    .navigate .wrapper > ul > li:last-child a {
      background: #9a1908;
      line-height: 41px;
      margin: 7px 0; }
      @media screen and (max-width: 1300px) {
        .navigate .wrapper > ul > li:last-child a {
          display: none; } }
      .navigate .wrapper > ul > li:last-child a:hover {
        background: #6b1105;
        color: #f9be0c; }
    .navigate .wrapper > ul > li {
      position: relative; }
      .navigate .wrapper > ul > li > a {
        color: white;
        font-family: 'Russo One', sans-serif;
        font-size: 18px;
        text-transform: uppercase;
        line-height: 55px;
        text-decoration: none;
        padding: 0 20px;
        display: block;
        transition: all .5s ease; }
        .navigate .wrapper > ul > li > a:hover {
          color: #f9be0c; }
        @media screen and (max-width: 1300px) {
          .navigate .wrapper > ul > li > a {
            line-height: 40px;
            text-align: center;
            border-top: 1px dotted rgba(255, 255, 255, 0.5); } }
      .navigate .wrapper > ul > li:hover {
        background: #434343; }
        @media screen and (max-width: 1300px) {
          .navigate .wrapper > ul > li:hover {
            background: none; } }
      .navigate .wrapper > ul > li:hover > ul {
        display: block; }
      .navigate .wrapper > ul > li > ul {
        background: #434343;
        position: absolute;
        top: 55px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        z-index: 50;
        min-width: 320px;
        display: none; }
        @media screen and (max-width: 1300px) {
          .navigate .wrapper > ul > li > ul {
            position: relative;
            display: block;
            text-align: center;
            top: 0;
            background: none; } }
        .navigate .wrapper > ul > li > ul > li {
          border-top: 1px dotted rgba(255, 255, 255, 0.25); }
          .navigate .wrapper > ul > li > ul > li > a {
            font-weight: bold;
            padding: 10px 20px;
            display: block;
            color: #ffffff !important;
            text-decoration: none;
            background: #434343 !important;
            transition: all .5s ease; }
            @media screen and (max-width: 1300px) {
              .navigate .wrapper > ul > li > ul > li > a {
                padding-top: 5px;
                padding-bottom: 5px;
                font-size: 14px; } }
            .navigate .wrapper > ul > li > ul > li > a:hover {
              background: #212121 !important;
              color: #f9be0c !important; }
    .navigate .wrapper > ul li.active a {
      background: #434343; }
